import React, { FC, useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

interface Props {
  content: string;
  onChange?: (text: string, idx: any, type?: 'source' | 'target') => void;
  lang?: string;
  disabled?: boolean;
  type?: string;
  index?: number;
  isLoading?: boolean;
}

const sanitizeConf = {
  allowedTags: ['p'],
  allowedAttributes: {},
};

const EditableCell: FC<Props> = ({
  content,
  onChange,
  disabled,
  index,
  type,
  isLoading,
}) => {
  const ref = useRef(null);
  const [text, setText] = useState(content || '');

  useEffect(() => {
    if (onChange && type === 'source') {
      onChange(text, index, type as 'source' | 'target');
    }
  }, [text]);

  if (isLoading) {
    return (
      <ContentEditable
        innerRef={ref}
        dir="auto"
        html={text} // innerHTML of the editable div
        disabled={disabled} // use true to disable editing
        onChange={(e) => setText(sanitizeHtml(e.target.value, sanitizeConf))} // handle innerHTML change
        tagName="article" // Use a custom HTML tag (uses a div by default)
      />
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ContentEditable
        innerRef={ref}
        dir="auto"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();

            if (onChange) {
              // @ts-ignore
              onChange(e.target.textContent, index, type);
            }
          }
        }}
        html={text} // innerHTML of the editable div
        disabled={disabled} // use true to disable editing
        onChange={(e) => setText(sanitizeHtml(e.target.value, sanitizeConf))} // handle innerHTML change
        tagName="article" // Use a custom HTML tag (uses a div by default)
      />
    </form>
  );
};

export default EditableCell;
