import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { request } from '../../../../shared/utils/api';
import { Order } from '../../Order.interface';
interface Props {
  open: boolean;
  selectedIds: Number[];
  currentOrders: Order[] | undefined;
  onClose: () => void;
  onSubmit: (e?: any) => void;
}

const useStyles = makeStyles({
  bold: {
    fontWeight: 600,
  },
});

const CloseOrderDialog: FC<Props> = ({
  open,
  selectedIds,
  currentOrders,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();

  const [ordersNotSentToClient, setOrdersNotSentToClient] = useState<
    Order[] | undefined
  >();
  const [ordersSentToClient, setOrdersSentToClient] = useState<
    Order[] | undefined
  >();

  const closeOrder = () => {
    request(`api/close_order/`, {
      method: 'POST',
      body: JSON.stringify({
        close_orders_with_ids: selectedIds,
      }),
    }).then((r) => {
      onClose();
      onSubmit();
    });
  };

  const checkOrderStatus = () => {
    const filteredOrdersNotSentToClient = currentOrders?.filter(
      (order) => order.sent_email !== true,
    );
    const filteredOrdersSentToClient = currentOrders?.filter(
      (order) => order.sent_email === true,
    );

    if (filteredOrdersNotSentToClient) {
      setOrdersNotSentToClient(filteredOrdersNotSentToClient);
    }

    if (filteredOrdersSentToClient) {
      setOrdersSentToClient(filteredOrdersSentToClient);
    }
  };

  useEffect(() => {
    if (currentOrders) {
      checkOrderStatus();
    }
  }, [selectedIds]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>Close order</DialogTitle>

      <DialogContent>
        {ordersNotSentToClient && ordersNotSentToClient?.length > 0 ? (
          <Box>
            <Box>
              <Box mb={2}>
                <Typography variant="body1">
                  You still have some order's that have not been sent to the
                  client. Do you want to proceed before sending these orders?
                  You won’t be able to open them again!
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.bold}>
                  Order Ids that have not been sent:
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  maxWidth: 'md',
                }}
                flexWrap="flex-wrap"
              >
                {ordersNotSentToClient?.map(({ order_id: orderId }, index) => (
                  <Box paddingRight={1} key={orderId}>
                    <Typography>
                      {orderId}
                      {index !== ordersNotSentToClient?.length - 1 ? ', ' : ''}
                    </Typography>
                  </Box>
                ))}
              </Box>

              {ordersSentToClient && ordersSentToClient?.length > 0 && (
                <Box>
                  <Box mt={2}>
                    <Typography className={classes.bold}>
                      Sent Order Ids:
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'inline-flex' }}>
                    {ordersSentToClient?.map(({ order_id: orderId }, index) => (
                      <Box paddingRight={1} key={orderId}>
                        <Typography>
                          {orderId}
                          {index !== ordersSentToClient?.length - 1 ||
                          (index !== 0 && ordersSentToClient?.length === 1)
                            ? ', '
                            : ''}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Typography variant="body1">
            Are you sure you want to close{' '}
            {selectedIds && selectedIds?.length > 1 ? 'these' : 'this'} order
            {selectedIds && selectedIds?.length > 1 ? 's' : ''}? You won’t be
            able to open{' '}
            {selectedIds && selectedIds?.length > 1 ? 'them' : 'it'} again!
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button onClick={closeOrder} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseOrderDialog;
