import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { request } from '../../../../shared/utils/api';

interface Props {
  open: boolean;
  selectedIds: Number[];
  onClose: () => void;
  onSubmit: (e?: any) => void;
}

const ReopenOrderDialog: FC<Props> = ({
  open,
  selectedIds,
  onClose,
  onSubmit,
}) => {
  const openOrder = () => {
    request(`api/open_order/`, {
      method: 'POST',
      body: JSON.stringify({
        open_orders_with_ids: selectedIds,
      }),
    }).then((r) => {
      onClose();
      onSubmit();
    });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        Re-open order{selectedIds && selectedIds?.length > 1 ? 's' : ''}
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to open{' '}
          {selectedIds && selectedIds?.length > 1 ? 'these' : 'this'} order
          {selectedIds && selectedIds?.length > 1 ? 's' : ''}?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button onClick={openOrder} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReopenOrderDialog;
