import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { request } from '../../../../shared/utils/api';
interface Props {
  open: boolean;
  selectedIds: () => number;
  onClose: () => void;
  onSubmit: (e?: any) => void;
}

const ClientEmailDialog: FC<Props> = ({
  open,
  onClose,
  onSubmit,
  selectedIds,
}) => {
  const h = useHistory();
  const [responseData, setResponseData] = useState<any>({
    info: '',
    not_finished_orders: [],
    successfull_orders: [],
    errors: [],
  });

  const sendEmail = () => {
    request(`api/send_email/`, {
      method: 'POST',
      body: JSON.stringify({
        email_orders_with_ids: selectedIds,
      }),
    }).then((r) => {
      if (typeof r === 'undefined') {
        setResponseData({
          info: 'Failed',
          errors: [
            {
              message:
                'Failed to send the client an email, please try again later.',
            },
          ],
        });
        onClose();

        return;
      }

      setResponseData({
        info: r?.info,
        not_finished_orders: r?.not_finished_orders,
        successfull_orders: r?.successfull_orders,
        errors: r?.errors,
      });

      if (r?.errors?.length > 0) {
        onClose();
        if (r?.successfull_orders?.length > 0) {
          onSubmit();
        }
      } else {
        onClose();
        onSubmit();
      }
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        open={responseData.info ? true : false}
        onClose={() => setResponseData([])}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Typography variant="h5">{responseData.info}</Typography>

          {responseData?.successfull_orders?.length > 0 && (
            <Box>
              <Box mt={1}>
                <Typography variant="body1" component="h5">
                  Successfull orders:
                </Typography>
              </Box>

              <Box sx={{ flexDirection: 'column' }}>
                <Typography variant="body1" component="p">
                  {responseData.successfull_orders.join(', ')}
                </Typography>
              </Box>
            </Box>
          )}

          {responseData?.errors?.length > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box mt={1}>
                <Typography variant="body1" component="h5">
                  Errors:
                </Typography>
              </Box>

              {responseData.errors.map(({ message, data, order_id }) => (
                <Box sx={{ display: 'inline-flex' }}>
                  {order_id && (
                    <Box paddingRight={1}>
                      <Typography variant="body1" component="p">
                        #{order_id}
                      </Typography>
                    </Box>
                  )}

                  <Box paddingRight={1}>
                    <Typography variant="body1" component="p">
                      {message}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="body1" component="p">
                      {data}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          {responseData.not_finished_orders &&
            responseData?.errors.length === 0 &&
            responseData.not_finished_orders.map((item) => (
              <Typography variant="body1" component="p">
                {item}
              </Typography>
            ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setResponseData([])}
            variant="contained"
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Send email to client</DialogTitle>
        <DialogContent>
          Make sure that clients orders have been finished
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button onClick={sendEmail} variant="contained" color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientEmailDialog;
