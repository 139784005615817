import {
  Box,
  Button,
  Card,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { Link, useParams } from 'react-router-dom';
import { InfoOutlined } from '@material-ui/icons';
// import data from './mockdata.json';
import PaymentInfoDialog from './components/PaymentInfoDialog';
import ProjectsTable from './components/ProjectsTable';
import { request } from '../../shared/utils/api';
import { Project, ProjectsDTO } from './Home.interface';
import Auth from '../../context/Auth';
import ClientEmailDialog from '../Order/components/ClientEmailDialog';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const Home = () => {
  const classes = useStyles();
  const [openPayment, setOpenPayment] = useState(false);
  const auth = Auth.useContainer();
  const { data, revalidate } = useSWR<ProjectsDTO>(
    ['api/get_projects_by_orderid/'],
    (url) => request(url, { method: 'POST', body: JSON.stringify({}) }),
  );

  return (
    <Box>
      <Box
        mt={5}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4">Hi, {auth.name}</Typography>
        <Box ml={3}>
          <Button
            startIcon={<InfoOutlined />}
            variant="contained"
            color="primary"
            onClick={() => setOpenPayment(true)}
          >
            Payment info
          </Button>
        </Box>
      </Box>

      <Box mt={3}>
        <Paper elevation={5}>
          <Box p={3}>
            <ProjectsTable loading={!data} data={data?.projects || []} />
          </Box>
        </Paper>
      </Box>
      <PaymentInfoDialog
        open={openPayment}
        name={auth.name}
        onClose={() => setOpenPayment(false)}
      />
    </Box>
  );
};

export default Home;
