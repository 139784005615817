/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Auth from './context/Auth';

const CopywriterRoute = ({ component: Component, ...rest }) => {

    const auth = Auth.useContainer();
    return (
        <Route
            {...rest}
            render={props =>
                !auth.staff ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/orders', state: { from: props.location } }} />
                )
            }
        />
    )
}

export default CopywriterRoute