import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  FormControl,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { IBugReport } from '../../interfaces/bugReport';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  open: boolean;
  errors?: string | undefined;
  onClose: () => void;
  onSubmit: (e?: any) => void;
}

const BugReportModal: FC<Props> = ({ open, errors, onClose, onSubmit }) => {
  const desktop = useMediaQuery('(min-width:600px)');

  const [formData, setFormData] = useState<IBugReport>({
    title: 'New Bug Report',
    link: '',
    description: '',
    urgency: 'high',
  });

  useEffect(() => {
    if (window.location.href) {
      setFormData({
        ...formData,
        link: window.location.href,
      });
    }
  }, [window]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>Bug Report</DialogTitle>

      <DialogContent>
        {errors && (
          <Typography variant="h5" color="secondary">
            {errors}
          </Typography>
        )}
        <form>
          <TextField
            autoFocus
            defaultValue={formData.title}
            onChange={(e) =>
              setFormData({
                ...formData,
                title: e.target.value,
              })
            }
            margin="dense"
            label="Title"
            type="text"
            fullWidth
          />

          <TextField
            autoFocus
            defaultValue={formData.link}
            onChange={(e) =>
              setFormData({
                ...formData,
                link: e.target.value,
              })
            }
            margin="dense"
            label="Link to where the bug happened"
            type="text"
            fullWidth
          />

          <Box py={2} width={'100%'}>
            <Box mb={1}>
              <InputLabel htmlFor="description">Description</InputLabel>
            </Box>
            <TextareaAutosize
              id="description"
              aria-label="description"
              placeholder="The file did not download when I..."
              autoFocus
              onChange={(e) =>
                setFormData({
                  ...formData,
                  description: e.target.value,
                })
              }
              style={{width: '100%'}}
            />
          </Box>

          <Box my={2} width={desktop ? '35%' : '100%'}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel>Urgency</InputLabel>
              <Select value={formData.urgency} placeholder="Urgency" fullWidth>
                {[
                  {
                    label: 'High (1-2 Hours)',
                    value: 'high',
                  },
                  {
                    label: 'Medium (24 Hours)',
                    value: 'medium',
                  },
                  {
                    label: 'Low (7 Days)',
                    value: 'low',
                  },
                ].map(({ label, value }, i) => (
                  <MenuItem
                    key={value}
                    value={value}
                    onClick={() => {
                      setFormData({
                        ...formData,
                        urgency: value,
                      });
                    }}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>

        <Button
          disabled={
            !formData.title ||
            !formData.description ||
            !formData.link ||
            !formData.urgency
          }
          onClick={() => onSubmit(formData)}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BugReportModal;
