import { createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import moment from 'moment';
import React, { FC, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import { Link, useHistory } from 'react-router-dom';
import { MovieFilter, Subtitles } from '@material-ui/icons';
import { Project } from '../../Home.interface';
import { request } from '../../../../shared/utils/api';

interface Props {
  data: Project[];
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: 'pointer',
    },
  }),
);

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', minWidth: 120, flex: 1 },
  {
    field: 'due_date',
    headerName: 'Time left',
    minWidth: 120,
    flex: 1,
    valueFormatter: (params) => {
      if (params.value === 'Overdue') {
        return 'Overdue';
      } else {
        return moment(
          `${params.value} ${params.row.due_time}`,
          'YYYY-MM-DD hh:mm:ss',
        ).fromNow();
      }
    },
    valueGetter: (params) => {
      if (
        moment(
          `${params.value} ${params.row.due_time}`,
          'YYYY-MM-DD hh:mm:ss',
        ).isBefore(new Date(), 'hour')
      ) {
        return 'Overdue';
      }
      return params.value;
    },
  },
  {
    field: 'duration',
    headerName: 'Duration (min)',
    minWidth: 100,
    flex: 1,
    valueFormatter: (params) => {
      let value;
      if (params.value) {
        value = Math.ceil(Number(params.value) / 60);
      } else {
        value = null;
      }
      return value;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'result_link',
    headerName: 'Results',
    minWidth: 120,
    flex: 1,
    renderCell: (params) => {
      if (params.value) {
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => {
                request('api/get_download_link/', {
                  method: 'POST',
                  body: JSON.stringify({
                    project_uuid: params.row.project_uuid,
                    to_lang: params.row.to_lang,
                  }),
                }).then((r) => {
                  const win = window.open(r.result_link, 'Download');
                  if (win != null) {
                    win.focus();
                  }
                });
              }}
            >
              <MovieFilter />
            </IconButton>
            {params.row.subs_link && (
              <IconButton
                color="primary"
                onClick={() => {
                  request('api/get_download_link/', {
                    method: 'POST',
                    body: JSON.stringify({
                      project_uuid: params.row.project_uuid,
                      to_lang: params.row.to_lang,
                    }),
                  }).then((r) => {
                    const win = window.open(r.subs_link, 'Download');
                    if (win != null) {
                      win.focus();
                    }
                  });
                }}
              >
                <Subtitles />
              </IconButton>
            )}
          </>
        );
      }
      return <></>;
    },
  },
];

const ProjectsTable: FC<Props> = ({ data = [], loading }) => {
  const history = useHistory();
  const classes = useStyles();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'due_date',
      sort: 'asc' as GridSortDirection,
    },
  ]);

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        sortingOrder={['desc', 'asc']}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        loading={loading}
        rows={data}
        autoHeight
        columns={columns}
        onRowClick={(e) => {
          history.push(`/project/${e.row.project_uuid}/${e.row.to_lang}`);
        }}
        pageSize={30}
        getRowId={(row) => row.project_uuid}
      />
    </div>
  );
};

export default ProjectsTable;
