import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { createContainer } from 'unstated-next';
import { User } from '../shared/interfaces/user';
import { BACKEND_URL } from '../shared/utils/config';
import { request } from '../shared/utils/api';

const useAuth = () => {
  const [token, setToken] = useLocalStorage<string>('jwt', '', { raw: true });
  const [staff, setStaff] = useLocalStorage<boolean>('staff', false);
  const [name, setName] = useLocalStorage<string>('name', '', { raw: true });

  const [loggedIn, setIsLoggedIn] = useState(false);

  const h = useHistory();

  useEffect(() => {
    if (!token && h.location.pathname !== '/sign-in') h.push('/sign-in');
  }, [h.location.pathname, token]);

  const login = (body) => {
    // eslint-disable-next-line no-undef
    fetch(`${BACKEND_URL}/api/token/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    })
      .then((res) => res.json())
      .then((json) => {
        window?.localStorage.setItem('jwt', json?.token);
        setName(json?.user?.username);
        setToken(json?.token);
        setStaff(json?.user?.is_admin);
        setIsLoggedIn(true);
      });
  };

  const whoami = () => {
    // eslint-disable-next-line no-undef
    fetch(`${BACKEND_URL}/api/whoami/`, {
      headers: {
        Authorization: `JWT ${window?.localStorage.getItem('jwt')}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setName(json?.username);
        setStaff(json?.is_admin);
        setIsLoggedIn(true);
      });
  };

  useEffect(() => {
    if (loggedIn) {
      whoami();
    }
  }, []);

  const logout = () => {
    setToken('');
    setStaff(false);
    setName('');
    window?.localStorage.removeItem('jwt');
  };

  return {
    staff,
    name,
    token,
    login,
    logout,
  };
};

export default createContainer(useAuth);
