import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './App.scss';
import {
  createTheme,
  CssBaseline,
  useMediaQuery,
  ThemeProvider,
} from '@material-ui/core';
import Dashboard from './pages/Home';
import AdminDashboard from './pages/AdminDashboard';
import Order from './pages/Order';
import SignIn from './pages/SignIn';
import Layout from './shared/components/Layout';
import AdminRoute from './AdminRoute';
import CopywriterRoute from './CopywriterRoute';
import Auth from './context/Auth';
import ProjectDetails from './pages/ProjectDetails';
import Languages from './context/Languages';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );


  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router basename="/">
          <Auth.Provider>
            <Languages.Provider>
              <Switch>
                <CopywriterRoute exact path="/" component={props =>
                    <Layout>
                      <Dashboard />
                    </Layout>
                } />
                <Route exact path="/project/:id/:toLang">
                  <Layout>
                    <ProjectDetails />
                  </Layout>
                </Route>
                <AdminRoute exact path="/orders" component={props =>
                    <Layout>
                      <Order />
                    </Layout>
                } />
                <AdminRoute exact path="/orders/:id/" component={props =>
                    <Layout>
                      <AdminDashboard />
                    </Layout>
                } />
                <Route path="/sign-in">
                  <SignIn />
                </Route>
                <Redirect to="/sign-in" />
              </Switch>
            </Languages.Provider>
          </Auth.Provider>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
