import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { VoicesLangsDTO } from '../pages/ProjectDetails/ProjectDetails.interface';
import { request } from '../shared/utils/api';
import Auth from './Auth';

const useLanguages = () => {
  const auth = Auth.useContainer();
  const [languagesAndVoices, setLanguagesAndVoices] = useState<VoicesLangsDTO>({
    languages: [],
  });

  useEffect(() => {
    if (auth.token && !languagesAndVoices.languages.length) {
      request(`api/voices_and_languages/`).then((r) => {
        setLanguagesAndVoices(r);
      });
    }
  }, [auth]);

  return {
    languagesAndVoices,
  };
};

export default createContainer(useLanguages);
