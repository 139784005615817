/* eslint-disable camelcase */
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Typography,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import React, {FC, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import Languages from '../../../../context/Languages';
import { request } from '../../../../shared/utils/api';
import { BACKEND_URL } from '../../../../shared/utils/config';

interface Props {
    open: boolean;
    name: string|undefined;
    onClose: () => void;

}


const  PaymentInfoDialog: FC<Props> = ({ open, onClose, name }) => {
    const h = useHistory();

    const [data, setData] = useState({
            mins_done_week: 0,
            earned_week: 0,
            payment_date: 0,
            mins_done_total: 0,
            earned_total: 0,
    });

    useEffect(() => {
        request('api/get_payment_info/').then((r) => {
            setData(r);
        })
    }, []);

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>Payment info for {name}</DialogTitle>
            <DialogContent>
                <Typography variant="overline" display="block">
                    Minutes done this week {data?.mins_done_week} min and {data?.earned_week} earned
                </Typography>
                <Typography variant="overline" display="block">
                    Next payment will be sent on {data?.payment_date}
                </Typography>
                <Divider />
                <Typography variant="overline" display="block">
                    Total minutes done: {data?.mins_done_total} min.
                </Typography>
                <Typography variant="overline" display="block">
                    Earned with Blanc: {data?.earned_total}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentInfoDialog;
