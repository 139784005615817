import {
  Box,
  Button,
  ButtonGroup,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
// import data from './mockdata.json';
import {
  CreateOutlined,
  EmailOutlined,
  DoneOutlined,
  DeleteOutlined,
} from '@material-ui/icons';
import OrdersTable from './components/OrdersTable';
import { request } from '../../shared/utils/api';
import { Order, OrdersDTO } from './Order.interface';
import CloseOrderDialog from './components/CloseOrderDialog';
import CreateOrderDialog from './components/CreateOrderDialog';
import ClientEmailDialog from './components/ClientEmailDialog';
import Auth from '../../context/Auth';
import ReopenOrderDialog from './components/ReopenOrderDialog';
import { styled } from '@material-ui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const TopSection = styled('div')(({ theme }: { theme: any }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    marginTop: '2em',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '2em',
  },
}));

const Heading = styled('div')(({ theme }: { theme: any }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.4em',
    textAlign: 'center',
    marginBottom: '2em',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1em',
    margin: '0',
  },
}));

const ButtonContainer = styled('div')(({ theme }: { theme: any }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const Orders = () => {
  const classes = useStyles();
  const location: { state: { previousPage: number, previousShowAll: boolean } } = useLocation();
  const [openClose, setOpenClose] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const [reopenOrder, setReopenOrder] = useState(false);
  const [refreshDataGrid, setRefreshDataGrid] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]) as any;
  const [currentOrders, setCurrentOrders] = useState<Order[] | undefined>();
  const [filteredData, setFilteredData] = useState<Order[] | undefined>();

  const auth = Auth.useContainer();

  const desktop = useMediaQuery('(min-width:600px)');

  const { data, revalidate } = useSWR<OrdersDTO>(
    auth?.token ? `api/get_orderbook/` : null,
    request,
  );

  const handleSetSelectedIds = (ids: number[]) => {
    setSelectedIds(ids);
    data?.orders &&
      setCurrentOrders(
        data?.orders?.filter(({ order_id }) => ids.includes(order_id)),
      );
  };

  const disableOpenButton = () => {
    const closedOrders =
      currentOrders?.filter(
        (order) => order.status === 'Closed' || order.closed,
      ) || [];

    if (closedOrders?.length > 0) {
      return false;
    }
    return true;
  };

  const refreshGrid = () => {
    setSelectedIds([]);
    revalidate();
    setRefreshDataGrid(!refreshDataGrid);
  };

  useEffect(() => {
    if (showAll && data?.orders) {
      setFilteredData(data?.orders);
    } else if (!showAll && data?.orders) {
      const openOrders = data?.orders?.filter(
        (order) => !order.closed && order.status !== 'Closed',
      );
      setFilteredData(openOrders);
    }
  }, [auth?.token, data, showAll]);

  useEffect(() => {
    if (location?.state?.previousShowAll !== showAll) {
      setShowAll(location?.state?.previousShowAll || false)
    }
  }, [location?.state?.previousShowAll])

  return (
    <Box>
      <TopSection>
        <Heading>
          <Typography variant="h5" component="h1">
            Orders
          </Typography>
        </Heading>

        <ButtonContainer>
          <ButtonGroup variant="outlined">
            <Button
              startIcon={<CreateOutlined />}
              color="primary"
              size="small"
              className="button"
              onClick={() => setOpenCreate(true)}
            >
              {desktop ? 'New Order' : 'New'}
            </Button>

            <Button
              startIcon={<EmailOutlined />}
              color="primary"
              size="small"
              disabled={!selectedIds.length}
              onClick={() => setOpenClient(true)}
            >
              {desktop ? 'Email to client' : 'Email'}
            </Button>

            {showAll && (
              <Button
                startIcon={<DoneOutlined />}
                color="default"
                size="small"
                disabled={!selectedIds.length || disableOpenButton()}
                onClick={() => setReopenOrder(true)}
              >
                {desktop ? 'Re-Open Order' : 'Open'}
              </Button>
            )}

            <Button
              startIcon={<DeleteOutlined />}
              color="secondary"
              size="small"
              disabled={
                !selectedIds.length ||
                !currentOrders?.some((order) => !order.closed)
              }
              onClick={() => setOpenClose(true)}
            >
              {desktop ? 'Close Order' : 'close'}
            </Button>
          </ButtonGroup>
        </ButtonContainer>
      </TopSection>

      <Box mt={3}>
        <Paper elevation={5}>
          <Box p={3}>
            <OrdersTable
              loading={!filteredData}
              data={filteredData || []}
              selectedIds={selectedIds}
              setSelectedIds={handleSetSelectedIds}
              refreshDataGrid={refreshDataGrid}
              setRefreshDataGrid={setRefreshDataGrid}
              showAll={showAll}
              setShowAll={setShowAll}
            />
          </Box>
        </Paper>
      </Box>

      <ClientEmailDialog
        open={openClient}
        onClose={() => setOpenClient(false)}
        onSubmit={refreshGrid}
        selectedIds={selectedIds}
      />

      <CreateOrderDialog
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        onSubmit={refreshGrid}
      />

      <ReopenOrderDialog
        open={reopenOrder}
        onClose={() => setReopenOrder(false)}
        onSubmit={refreshGrid}
        selectedIds={selectedIds}
      />

      <CloseOrderDialog
        open={openClose}
        onClose={() => setOpenClose(false)}
        onSubmit={refreshGrid}
        selectedIds={selectedIds}
        currentOrders={currentOrders}
      />
    </Box>
  );
};

export default Orders;
