import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Copywriter,
  CopywritersDTO,
  VoicesLangsDTO,
} from '../../ProjectDetails.interface';
import Auth from '../../../../context/Auth';
import { request } from '../../../../shared/utils/api';
interface Props {
  data: VoicesLangsDTO;
  projectId: string;
  sourceComplete?: boolean;
  admins: string[];
  onChange: (e: any) => void;
  refreshProject: Function;
  done: boolean;
  initialValues: {
    admin: string | undefined;
    sourceCopywriter?: string;
    targetCopywriter?: string;
    from: string;
    to: string;
    voice: string;
    bas: boolean | null;
    subtitles: boolean | null;
    lipSync: boolean | null;
  };
  multispeaker: boolean | string | undefined;
  setMultispeaker: Function;
}

const DetailsForm: FC<Props> = ({
  data,
  projectId,
  sourceComplete,
  onChange,
  refreshProject,
  initialValues,
  multispeaker,
  setMultispeaker,
  done,
  admins,
}) => {
  const auth = Auth.useContainer();
  const desktop = useMediaQuery('(min-width:600px)');

  const targetLangs = data.languages.filter((i) => i.to);
  const sourceLangs = data.languages.filter((i) => i.from_field);
  const [source, setSource] = useState(initialValues.from);
  const [admin, setAdmin] = useState(initialValues.admin);
  const [sourceCopywriter, setSourceCopywriter] = useState(
    initialValues.sourceCopywriter,
  );
  const [targetCopywriter, setTargetCopywriter] = useState(
    initialValues.targetCopywriter,
  );
  const [target, setTarget] = useState(initialValues.to);
  const [voice, setVoice] = useState(initialValues.voice);
  const [lipSync, setLipSync] = useState(initialValues.lipSync || false);
  const [bas, setBas] = useState(initialValues.bas || false);
  const [subtitles, setSubtitles] = useState(initialValues.subtitles || false);
  const [sourceCopywriters, setSourceCopywriters] = useState<Copywriter[]>([]);
  const [targetCopywriters, setTargetCopywriters] = useState<Copywriter[]>([]);

  // if allocation_type == "source":
  // 	Projects.objects.filter(project_uuid=project_uuid, to_lang=to_lang).update(
  // 		source_copywriter=username, source_allocation_status="In Progress", status="In Progress", source_finished=False
  // 	)
  // elif allocation_type == "target":
  // 	Projects.objects.filter(project_uuid=project_uuid, to_lang=to_lang).update(
  // 		target_copywriter=username, target_allocation_status="In Progress", status="In Progress"
  // 	)

  useEffect(() => {
    if (source || target) {
      request('api/get_copywriters/', {
        method: 'POST',
        body: JSON.stringify({
          from_lang: source,
          to_lang: target,
        }),
      }).then((r: CopywritersDTO) => {
        setSourceCopywriters(r?.source_copywriters);
        setTargetCopywriters(r?.target_copywriters);
      });
    }
  }, [data]);

  const updateCopywriter = (
    allocationType: 'source' | 'target',
    copywriter: string,
  ) => {
    request(`api/allocate_copywriter/`, {
      method: 'POST',
      body: JSON.stringify({
        username: copywriter,
        project_uuid: projectId,
        from_lang: source,
        to_lang: target,
        allocation_type: allocationType,
      }),
    }).then((r) => {
      refreshProject();
      if (allocationType === 'source') {
        setSourceCopywriter(copywriter);
      } else {
        setTargetCopywriter(copywriter);
      }
    });
  };

  const handleMultispeakerToggle = async () => {
    const nextState = multispeaker ? false : true;
    await request(`api/edit_project_setup/`, {
      method: 'POST',
      body: JSON.stringify({
        project_uuid: projectId,
        to_lang: target,
        params_to_edit: [['multispeaker', nextState]],
      }),
    })
      .then(() => {
        setMultispeaker(nextState);
        refreshProject();
      })
      .catch((err) => {
        setMultispeaker(true);
      });
  };

  let targetVoices = [] as any;
  targetLangs
    .filter(
      (i) =>
        i.lang_short_name.slice(0, 2) ===
        data.languages
          .find((i) => i.lang_name === initialValues.to)
          ?.lang_short_name.slice(0, 2),
    )
    .map((item) => item.voices.map((voice) => targetVoices.push(voice)));
  targetVoices.sort();
  const triggerChange = () => {
    onChange({
      admin,
      sourceCopywriter,
      targetCopywriter,
      source,
      target,
      voice,
      lipSync,
      bas,
      subtitles,
    });
  };

  useEffect(() => {
    triggerChange();
  }, [
    admin,
    sourceCopywriter,
    targetCopywriter,
    source,
    target,
    voice,
    lipSync,
    bas,
    subtitles,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={4}>
        <Grid container spacing={1}>
          <Grid item>
            <Box width={desktop ? 200 : 140}>
              <FormControl
                disabled={done}
                variant="outlined"
                fullWidth
                size="small"
              >
                <InputLabel id="demo-simple-select-label">Admin</InputLabel>
                <Select
                  disabled={!auth.staff}
                  value={admin || ''}
                  placeholder="Admin"
                  fullWidth
                  onChange={(e) => {
                    setAdmin(e.target.value as string);
                  }}
                >
                  {admins?.map((value: any, i) => (
                    <MenuItem key={`${value.username}-${i}`} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item>
            <Box width={desktop ? 200 : 140}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>
                  {desktop ? 'Source Copywriter' : 'Source Cop...'}
                </InputLabel>
                <Select
                  value={sourceCopywriter || ''}
                  placeholder="Source Copywriter"
                  fullWidth
                  disabled={!auth.staff || sourceComplete === true}
                  onChange={({ target: { value } }) => {
                    updateCopywriter('source', value as string);
                  }}
                >
                  {sourceCopywriters?.map((value, i) => (
                    <MenuItem
                      key={`${value.username}-${i}`}
                      value={value.username}
                      onClick={() => {
                        if (sourceCopywriter === value.username) {
                          updateCopywriter('source', value.username as string);
                        }
                      }}
                    >
                      {value?.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item>
            <Box width={desktop ? 200 : 140}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>
                  {' '}
                  {desktop ? 'Target Copywriter' : 'Target Copy...'}
                </InputLabel>

                <Select
                  value={targetCopywriter || ''}
                  placeholder="Target Copywriter"
                  fullWidth
                  disabled={!auth.staff}
                  onChange={({ target: { value } }) => {
                    updateCopywriter('target', value as string);
                  }}
                >
                  {targetCopywriters?.map((value, i) => (
                    <MenuItem
                      key={`${value.username}-${i}`}
                      value={value.username}
                      onClick={() => {
                        if (targetCopywriter === value.username) {
                          updateCopywriter('target', value.username as string);
                        }
                      }}
                    >
                      {value?.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} md={5}>
        <Grid container spacing={1}>
          <Grid item>
            <Box width={desktop ? 200 : 140}>
              <FormControl
                disabled={done}
                variant="outlined"
                fullWidth
                size="small"
              >
                <InputLabel id="demo-simple-select-label">
                  Source language
                </InputLabel>
                {sourceLangs.length > 0 && (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={source}
                    placeholder="Source language"
                    fullWidth
                    disabled
                    onChange={(e) => {
                      setSource(e.target.value as string);
                    }}
                  >
                    {sourceLangs.map((lang) => (
                      <MenuItem key={lang.lang_name} value={lang.lang_name}>
                        {lang.lang_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Box>
          </Grid>

          <Grid item>
            <Box width={desktop ? 200 : 140}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>Target language</InputLabel>
                {targetLangs.length > 0 && (
                  <Select
                    disabled
                    value={target}
                    placeholder="Target language"
                    fullWidth
                    onChange={(e) => {
                      setTarget(e.target.value as string);
                    }}
                  >
                    {targetLangs.map((lang) => (
                      <MenuItem key={lang.lang_name} value={lang.lang_name}>
                        {lang.lang_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Box>
          </Grid>

          {targetVoices && (
            <Grid item>
              <Box width={desktop ? 200 : 140}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel>Voice</InputLabel>
                  {targetVoices.length > 0 && (
                    <Select
                      disabled={!auth.staff}
                      value={voice}
                      placeholder="Voice"
                      fullWidth
                      onChange={(e) => {
                        setVoice(e.target.value as string);
                      }}
                    >
                      {targetVoices.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} md={3}>
        <Grid container>
          <Grid item>
            <Box>
              <FormControlLabel
                disabled={done || !auth.staff}
                control={
                  <Checkbox
                    checked={lipSync}
                    onChange={(e) => setLipSync(!lipSync)}
                  />
                }
                label="LipSync"
              />
            </Box>
          </Grid>

          <Grid item>
            <Box>
              <FormControlLabel
                disabled={done || !auth.staff}
                control={
                  <Checkbox
                    checked={bas}
                    onChange={(e) => {
                      setBas(!bas);
                    }}
                  />
                }
                label="BAS"
              />
            </Box>
          </Grid>

          <Grid item>
            <Box>
              <FormControlLabel
                disabled={done || !auth.staff}
                control={
                  <Checkbox
                    checked={subtitles}
                    onChange={(e) => {
                      setSubtitles(!subtitles);
                    }}
                  />
                }
                label="Subtitles"
              />
            </Box>
          </Grid>

          <Grid item>
            <Box>
              <FormControlLabel
                disabled={done || !auth.staff}
                control={
                  <Checkbox
                    checked={multispeaker ? true : false}
                    onChange={(e) => handleMultispeakerToggle()}
                  />
                }
                label="Multispeaker"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsForm;
