/* eslint-disable react/jsx-props-no-spreading */

import React, { FC, useState } from 'react';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import { Box } from '@material-ui/core';

interface Props {
  disabled?: boolean;
  settings?: any;
  label?: string;
}

const Uploader: FC<Props> = ({ disabled, settings, label }) => {
  const [files, setFiles] = useState([]);
  return (
    <Box width="100%">
      <FilePond
        disabled={disabled}
        files={files}
        // @ts-ignore
        onupdatefiles={setFiles}
        allowMultiple={false}
        maxFiles={1}
        server="/api"
        // {/* sets the file input name, it's filepond by default */}
        name="files"
        labelIdle="Upload"
        {...settings}
      />
    </Box>
  );
};

export default Uploader;
