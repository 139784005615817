import { Box, Grid, Button } from '@material-ui/core';
import { NoteAdd, DoneAll, GroupAddOutlined } from '@material-ui/icons';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { request } from '../../../../shared/utils/api';
import Auth from '../../../../context/Auth';
import AllocateCopywriterDialog from '../AllocateCopywriterDialog';

interface Props {
  done: boolean;
  sourceFinished: boolean;
  fromLang: string;
  toLang: string;
  sourceCopywriter: string;
  targetCopywriter: string;
  refreshProject: Function;
}

const ProjectActions: FC<Props> = ({
  done,
  sourceFinished,
  fromLang,
  toLang,
  sourceCopywriter,
  targetCopywriter,
  refreshProject,
}) => {
  const { id } = useParams<{ id: string }>();
  const auth = Auth.useContainer();
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Grid container spacing={2}>
        {auth.staff ? (
          <Grid item>
            <Button
              disabled={done}
              startIcon={<GroupAddOutlined />}
              variant="outlined"
              color="default"
              onClick={() => setOpen(true)}
            >
              Allocate
            </Button>

            <AllocateCopywriterDialog
              open={open}
              onClose={() => setOpen(false)}
              id={id}
              fromLang={fromLang}
              toLang={toLang}
              sourceCopywriter={sourceCopywriter}
              targetCopywriter={targetCopywriter}
              sourceFinished={sourceFinished}
              refreshProject={refreshProject}
            />
          </Grid>
        ) : (
          ``
        )}
      </Grid>
    </Box>
  );
};

export default ProjectActions;
