import {
  Button,
  createStyles,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Switch,
  Theme,
} from '@material-ui/core';
import { compareAsc, isValid } from 'date-fns';
import moment from 'moment';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import { useHistory, useLocation } from 'react-router-dom';
import { Order } from '../../Order.interface';
import { styled } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const TopSection = styled('div')(({ theme }: { theme: any }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const ActiveOrders = styled('h6')(({ theme }: { theme: any }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2em',
    padding: '0',
    margin: '0 0 1em 0',
    width: '40%',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.4em',
    padding: '0',
    margin: '0 0 1em 0',
    width: '40%',
  },
}));

const TopFilterSection = styled('div')(({ theme }: { theme: any }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    width: '60%',
    margin: '0 0 1em 0',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '60%',
    margin: '0 0 1em 0',
  },
}));

interface Props {
  data: Order[];
  loading: boolean;
  selectedIds: any;
  setSelectedIds: any;
  refreshDataGrid: boolean;
  setRefreshDataGrid: Function;
  showAll: boolean;
  setShowAll: Function;
  previousPage?: number;
}

const columns: GridColDef[] = [
  {
    field: 'order_id',
    headerName: 'Order id',
    minWidth: 80,
    flex: 1,
  },
  {
    field: 'client_email',
    headerName: "Client's email",
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'created_date',
    headerName: 'Created on',
    minWidth: 160,
    flex: 1,
    type: 'date',
    renderCell: (params: GridRenderCellParams): React.ReactNode => {
      const dateToFormat = new Date(
        `${params.value as string}T${params.row.created_time as string}`,
      );

      if (!isValid(dateToFormat)) {
        return <span>params.value</span>;
      }

      return params.value
        ? moment(dateToFormat).format('YYYY-MM-DD, hh:mm:ss')
        : <span/>;

    },
    sortComparator: (v1, v2, param1, param2) => {
      const dateToFormat1 = moment(
        `${param1.value as string} ${
          param1.api.getCellValue(param1.id, 'created_time') as string
        }`,
      ).toDate();

      const dateToFormat2 = moment(
        `${param2.value as string} ${
          param2.api.getCellValue(param2.id, 'created_time') as string
        }`,
      ).toDate();

      const date1 = isValid(dateToFormat1) ? dateToFormat1 : '';

      const date2 = isValid(dateToFormat2) ? dateToFormat2 : '';

      return compareAsc(date1 as Date, date2 as Date);
    },
  },
  {
    field: 'due_date',
    headerName: 'Time left',
    minWidth: 90,
    flex: 1,
    valueFormatter: (params) => {
      if (params.value === 'Overdue') {
        return 'Overdue';
      } else {
        return moment(
          `${params.value} ${params.row.due_time}`,
          'YYYY-MM-DD hh:mm:ss',
        ).fromNow();
      }
    },
    valueGetter: (params) => {
      if (
        moment(
          `${params.value} ${params.row.due_time}`,
          'YYYY-MM-DD hh:mm:ss',
        ).isBefore(new Date(), 'hour')
      ) {
        return 'Overdue';
      }
      return params.value;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    flex: 1,
    sortComparator: (v1, v2, param1, param2) => {
      const sortingOrder = {
        0: 'Done',
        1: 'Re-opened',
        2: 'Not started',
        3: 'Closed',
      };

      let currentOrderKeyA = 0;
      let currentOrderKeyB = 0;

      for (let a in Object.keys(sortingOrder)) {
        if (v1 === sortingOrder[a]) {
          currentOrderKeyA = Number(a);
        }

        if (v2 === sortingOrder[a]) {
          currentOrderKeyB = Number(a);
        }
      }

      if (currentOrderKeyA < currentOrderKeyB) {
        return -1;
      }
      return 1;
    },
  },
];

const defaultSortModel = [
  {
    field: 'created_date',
    sort: 'desc' as GridSortDirection,
  },
  {
    field: 'status',
    sort: 'asc' as GridSortDirection,
  },
];

const OrdersTable: FC<Props> = ({
  data = [],
  loading,
  selectedIds,
  setSelectedIds,
  refreshDataGrid,
  setRefreshDataGrid,
  showAll,
  setShowAll,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location: {
    state: { previousPage: number; previousShowAll: boolean };
  } = useLocation();
  const [page, setPage] = useState(0);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const [checked, setChecked] = useState(true);
  const [activeOrderCount, setActiveOrderCount] = useState<
    number | undefined
  >();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setSortModel([
      {
        field: event.target.value,
        sort: 'desc' as GridSortDirection,
      },
      {
        field: 'status',
        sort: 'asc' as GridSortDirection,
      },
    ]);
  };

  useEffect(() => {
    const activeOrders = data.filter(
      (order) => !order.closed && order.status !== 'Closed',
    );

    setActiveOrderCount(activeOrders?.length || 0);
  }, [data]);

  // Ensure that page get's reset when returning (race condition)
  useEffect(() => {
    if (location?.state?.previousPage !== page) {
      new Promise((resolve) =>
        setTimeout(() => {
          resolve(setPage(location?.state?.previousPage || 0))
        }, 100),
      );
    }
  }, [location]);

  return (
    <>
      <TopSection>
        <ActiveOrders>
          Active orders: <b>{activeOrderCount}</b>
        </ActiveOrders>

        <TopFilterSection>
          <Button
            color="default"
            size="small"
            variant="outlined"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'Hide Closed' : 'Show All'}
          </Button>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  value={checked ? 'due_date' : 'created_date'}
                  inputProps={{
                    'aria-label': checked ? 'By Date' : 'By Urgency',
                  }}
                  checked={checked}
                  onChange={handleChange}
                />
              }
              label={checked ? 'By Date' : 'By Urgency'}
              labelPlacement="start"
            />
          </FormGroup>
        </TopFilterSection>
      </TopSection>

      <div style={{ height: 800, width: '100%' }}>
        <DataGrid
          // This will reset the data grid to clear all selections
          key={refreshDataGrid ? 'refresh-data-grid-key' : 'data-grid-key'}
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          loading={loading}
          autoPageSize
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => {
            setSelectedIds(ids);
          }}
          rows={data}
          columns={columns}
          onRowClick={(e) =>
            history.push(`/orders/${e.row.order_id}`, {
              previousPage: page,
              previousShowAll: showAll,
            })
          }
          rowHeight={35}
          rowsPerPageOptions={[50]}
          pageSize={25}
          pagination
          getRowId={(row) => row.order_id}
        />
      </div>
    </>
  );
};

export default OrdersTable;
