import React, { useEffect, useRef, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, TextField } from '@material-ui/core';
import { BACKEND_URL } from '../../shared/utils/config';
import Auth from '../../context/Auth';

function Copyright() {
  return (
    <Box mt={4}>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <span>cskeleto.dev</span> {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  btn: {
    margin: '20px auto',
  },
}));

const SignIn = () => {
  const classes = useStyles();
  // const location = useLocation();
  const history = useHistory();
  const [message, setMessage] = useState('');
  const auth = Auth.useContainer();

  useEffect(() => {
    if (auth.token) {
      setMessage('Logged in successfully, redirecting...');
      if (auth.staff) {
        setTimeout(() => history.push('/orders'), 1000);
      } else {
        setTimeout(() => history.push('/'), 1000);
      }
    }
  }, [auth]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    auth.login(JSON.stringify({ username, password }));
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        className={classes.paper1}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          {message && (
            <Typography
              className={classes.submit}
              component="h2"
              variant="caption"
            >
              {message}
            </Typography>
          )}
          <Box mt={3}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Login"
                    label="Login"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Password"
                    type="password"
                    label="Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      size="large"
                    >
                      Sign In
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Copyright />
        </div>
      </Grid>
    </Grid>
  );
};

export default SignIn;
