import { BACKEND_URL } from './config';

export const request = async (url: string, options?: RequestInit) => {
  const token = localStorage.getItem('jwt');
  try {
    return await fetch(`${BACKEND_URL}/${url}`, {
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        Authorization: `JWT ${token}`,
      },
      ...options,
    }).then((r) => {
      if (r.ok || r.status === 409) {
        return r.json();
      }
      if (r.status === 401 && token) {
        fetch(`${BACKEND_URL}/api/token/refresh/`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({ token }),
        }).then((res) => res.json());
        localStorage.removeItem('staff');
        localStorage.removeItem('jwt');
        localStorage.removeItem('name');
        if (!window.location.pathname.includes('sign-in')) {
          window.location.href = `${window.location.origin}/sign-in`;
        }
      }
      throw new Error('failed');
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const post = () => {};
