import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ButtonGroup,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  capitalize,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { User } from '../../../../shared/interfaces/user';
import { request } from '../../../../shared/utils/api';
import { Copywriter, CopywritersDTO } from '../../ProjectDetails.interface';

interface Props {
  open: boolean;
  id: string;
  fromLang: string;
  toLang: string;
  sourceCopywriter: string;
  targetCopywriter: string;
  sourceFinished: boolean;
  onClose: () => void;
  refreshProject: Function;
}

const AllocateCopywriterDialog: FC<Props> = ({
  open,
  onClose,
  id,
  fromLang,
  toLang,
  sourceFinished,
  sourceCopywriter,
  targetCopywriter,
  refreshProject,
}) => {
  const h = useHistory();
  const [sourceCopywriters, setSourceCopywriters] = useState<Copywriter[]>([]);
  const [targetCopywriters, setTargetCopywriters] = useState<Copywriter[]>([]);
  const [copywritersToBeAllocated, setCopywritersToBeAllocated] = useState<
    Copywriter[]
  >([]);
  const [value, setValue] = useState(sourceCopywriter || targetCopywriter);
  const [allocationType, setAllocationType] = useState<
    'source' | 'target' | undefined
  >();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const getCopywriters = () => {
    request('api/get_copywriters/', {
      method: 'POST',
      body: JSON.stringify({
        from_lang: fromLang,
        to_lang: toLang,
      }),
    }).then((r: CopywritersDTO) => {
      setSourceCopywriters(r?.source_copywriters);
      setTargetCopywriters(r?.target_copywriters);
    });
  };

  const onSubmit = () => {
    request(`api/allocate_copywriter/`, {
      method: 'POST',
      body: JSON.stringify({
        username: value,
        project_uuid: id,
        from_lang: fromLang,
        to_lang: toLang,
        allocation_type: allocationType,
      }),
    }).then((r) => {
      refreshProject();
      handleClose();
    });
  };

  const setCopywritersToAllocate = () => {
    let copywriters = [] as any[];

    if (allocationType === 'source') {
      copywriters = sourceCopywriters;
    } else {
      copywriters = targetCopywriters;
    }

    setCopywritersToBeAllocated(copywriters);
  };

  const handleClose = () => {
    if (allocationType) {
      setAllocationType(undefined);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    getCopywriters();
  }, []);

  useEffect(() => {
    setCopywritersToAllocate();
  }, [allocationType]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        <Box textAlign="center">
          {allocationType
            ? `Allocate a copywriter for the ${capitalize(allocationType)}.`
            : 'What would you like to allocate?'}
        </Box>
      </DialogTitle>

      <DialogContent>
        {!allocationType ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
            width="100%"
          >
            {!sourceCopywriter ? (
              <Button
                variant="outlined"
                color="default"
                size="small"
                onClick={() => setAllocationType('source')}
                style={{ padding: 'initial 5px' }}
              >
                Source
              </Button>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1" color="initial">
                  Source
                </Typography>

                <CheckCircle />
              </Box>
            )}

            {!targetCopywriter ? (
              <Button
                variant="outlined"
                color="default"
                size="small"
                onClick={() => setAllocationType('target')}
                style={{ padding: 'initial 5px' }}
              >
                Target
              </Button>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1" color="initial">
                  Target
                </Typography>

                <CheckCircle />
              </Box>
            )}
          </Box>
        ) : (
          <List>
            <RadioGroup
              defaultValue={sourceCopywriter || targetCopywriter}
              name="copywriter"
              onChange={handleChange}
            >
              {!copywritersToBeAllocated ||
                (copywritersToBeAllocated.length === 0 && (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="body1" color="initial">
                      There are currently no copywriters for the{' '}
                      {allocationType} language.
                    </Typography>
                  </Box>
                ))}

              {copywritersToBeAllocated?.map((copywriter) => {
                const labelId = `checkbox-list-label-${copywriter.username}`;

                return (
                  <ListItem key={copywriter.username} dense>
                    <ListItemIcon>
                      <FormControlLabel
                        value={copywriter.username}
                        control={<Radio />}
                        label={`${copywriter.first_name} ${copywriter.last_name} [${copywriter.username}]`}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                      id={labelId}
                      secondary={
                        <Typography variant="overline">
                          Workload:{' '}
                          {Math.ceil(Number(copywriter.workload) / 60)} min.
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
            </RadioGroup>
          </List>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          {allocationType
            ? 'Back'
            : sourceCopywriter && targetCopywriter
            ? 'Ok'
            : 'Do it later'}
        </Button>

        {allocationType && (
          <Button
            onClick={onSubmit}
            disabled={
              !value ||
              !copywritersToBeAllocated ||
              copywritersToBeAllocated.length === 0
            }
            variant="contained"
            color="primary"
          >
            {sourceCopywriter !== null && targetCopywriter !== null
              ? 'Ok'
              : 'Allocate'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AllocateCopywriterDialog;
