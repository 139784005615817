import {
  Box,
  IconButton,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React, { FC, Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Languages from '../../../../context/Languages';
import { request } from '../../../../shared/utils/api';
import { BACKEND_URL } from '../../../../shared/utils/config';
import Uploader from '../../../../shared/components/Uploader';
import Auth from '../../../../context/Auth';
import {
  Copywriter,
  CopywritersDTO,
} from '../../../ProjectDetails/ProjectDetails.interface';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ListItem } from '@mui/material';
import Divider from '@mui/material/Divider';
interface Props {
  open: boolean;
  orderId: string;
  onClose: () => void;
  onSubmit: (e?: any) => void;
}

interface ICopywriterList {
  [id: number]: Copywriter[];
}

const CreateProjectDialog: FC<Props> = ({
  open,
  onClose,
  onSubmit,
  orderId,
}) => {
  const auth = Auth.useContainer();
  const desktop = useMediaQuery('(min-width:600px)');
  const h = useHistory();

  const { languagesAndVoices } = Languages.useContainer();
  const [title, setTitle] = useState('');
  const [source, setSource] = useState('');
  const [uploadId, setUploadId] = useState('');
  const targetLangs = languagesAndVoices.languages.filter((i) => i.to);
  const sourceLangs = languagesAndVoices.languages.filter((i) => i.from_field);
  const [sourceCopywriter, setSourceCopywriter] = useState<
    string | undefined
  >();
  const [sourceCopywriters, setSourceCopywriters] = useState<Copywriter[]>([]);
  const [targetCopywriters, setTargetCopywriters] = useState<ICopywriterList>({
    0: [],
  });
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const defaultSetup = [
    [
      '',
      '',
      'subtitle:False',
      'lipsync:False',
      'bas:False',
      'multispeaker:False',
      'target_copywriter:',
    ],
  ]
  const [setup, addSetup] = useState(defaultSetup);

  const [isLoading, setLoading] = useState(false)

  const setCopywriters = async (target: string, idx?: number | undefined) => {
    // Allow this to get source copywriters by passing in undefined as the first param
    const fromLang = idx !== undefined ? source : target;
    const toLang = idx !== undefined ? target : '';

    await request('api/get_copywriters/', {
      method: 'POST',
      body: JSON.stringify({
        from_lang: fromLang,
        to_lang: toLang,
      }),
    }).then((r: CopywritersDTO) => {
      setSourceCopywriters(r?.source_copywriters);
      setTargetCopywriters({
        ...targetCopywriters,
        [idx as number]: r?.target_copywriters,
      });
    });
  };

  const setTarget = async (value: string, idx: number) => {
    setup[idx][0] = value;
    setup[idx][1] = '';

    await setCopywriters(value, idx);

    addSetup([...setup]);
  };

  const setVoice = (value, idx) => {
    setup[idx][1] = value;
    addSetup([...setup]);
  };

  const setSubtitle = (value, idx) => {
    setup[idx][2] =
      'subtitle:' +
      value.toString().charAt(0).toUpperCase() +
      value.toString().slice(1);
    addSetup([...setup]);
  };

  const setLipSync = (value, idx) => {
    setup[idx][3] =
      'lipsync:' +
      value.toString().charAt(0).toUpperCase() +
      value.toString().slice(1);
    addSetup([...setup]);
  };

  const setBas = (value, idx) => {
    setup[idx][4] =
      'bas:' +
      value.toString().charAt(0).toUpperCase() +
      value.toString().slice(1);
    addSetup([...setup]);
  };

  const setMultispeaker = (value, idx) => {
    setup[idx][5] =
      'multispeaker:' +
      value.toString().charAt(0).toUpperCase() +
      value.toString().slice(1);
    addSetup([...setup]);
  };

  const setTargetCopywriter = (value, idx) => {
    setup[idx][6] = `target_copywriter:${value}`;
    addSetup([...setup]);
  };

  const getId = async (error, file) => {
    // If no error, Processing of a file has been completed
    const loadVideo = (file) =>
      new Promise((resolve, reject) => {
        try {
          let video = document.createElement('video');
          video.preload = 'metadata';

          video.onloadedmetadata = function () {
            resolve(video);
          };

          video.onerror = function () {
            reject('Invalid video. Please select a video file.');
          };

          video.src = window.URL.createObjectURL(file);
        } catch (e) {
          reject(e);
        }
      });

    const video = (await loadVideo(file.file)) as any;

    if (video?.duration) {
      setVideoDuration(video?.duration);
    }

    if (file?.serverId) {
      await request(`api/file_upload_complete/`, {
        method: 'POST',
        body: JSON.stringify({ upload_id: file.serverId }),
      }).then(() => {
        setUploadId(file.serverId);
        setLoading(false)
      });
    }
  };

  const cancelAnimationFrames = () => {
    // Cancel animation frames from filepond causing slow uploads
    const thisWindow = window as any;
    const requestAnimationFrame =
      thisWindow.requestAnimationFrame ||
      thisWindow.mozRequestAnimationFrame ||
      thisWindow.webkitRequestAnimationFrame ||
      thisWindow.msRequestAnimationFrame;

    const cancelAnimationFrame =
      thisWindow.cancelAnimationFrame || thisWindow.mozCancelAnimationFrame;

    const start = Date.now();

    let myReq;

    function step(timestamp) {
      // it's important to update the requestId each time you're calling requestAnimationFrame
      myReq = requestAnimationFrame(step);
    }
    myReq = requestAnimationFrame(step);
    // @ts-ignore
    cancelAnimationFrame(myReq);
  };

  const uploadSettings = {
    allowMinimumUploadDuration: false,
    chunkUploads: true,
    chunkSize: 1048576 * 5, // 5MB
    maxParallelUploads: '1',
    onprocessfile: getId,
    onprocessfileprogress: (file, progress) => {
      setLoading(true)
      cancelAnimationFrames();
    },
    name: 'filepond',
    server: {
      url: `${BACKEND_URL}/api/fp`,
      process: {
        url: '/process/',
        headers: {
          Authorization: `JWT ${localStorage.jwt}`,
        },
      },
      patch: '/patch/',
      revert: '/revert/',
      fetch: '/fetch/?target=',
    },
  };

  const createProject = () => {
    request(`api/dash/projects/`, {
      method: 'POST',
      body: JSON.stringify({
        name: title,
        from_lang: source,
        source_copywriter: sourceCopywriter,
        target: setup,
        upload_id: uploadId,
        video_duration: videoDuration,
        order_id: orderId,
      }),
    }).then((r) => {
      onClose();
      onSubmit();
      addSetup(defaultSetup)
    });
  };

  const onAddSetup = (e) => {
    addSetup([
      ...setup,
      [
        '',
        '',
        'subtitle:False',
        'lipsync:False',
        'bas:False',
        'multispeaker:False',
        'target_copywriter:',
      ],
    ]);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>Create new project</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          onChange={(e) => setTitle(e.target.value)}
          margin="dense"
          label="Project name"
          type="text"
          fullWidth
        />
        <Box my={2}>
          {/* Row 1 */}
          <Grid container>
            <Grid item xs={5} md={4}>
              <Box width={desktop ? 150 : 100}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel>Source</InputLabel>
                  <Select
                    value={source}
                    placeholder="Source"
                    fullWidth
                    onChange={(e) => {
                      setSource(e.target.value as string);
                      setCopywriters(e.target.value as string);
                    }}
                  >
                    {sourceLangs.map((lang) => (
                      <MenuItem key={lang.lang_name} value={lang.lang_name}>
                        {lang.lang_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={7} md={8}>
              <Box
                width={desktop ? 180 : 160}
                sx={{
                  marginBottom: '1em',
                }}
              >
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel>
                    {desktop ? 'Source Copywriter' : 'Source Copyw...'}
                  </InputLabel>
                  <Select
                    value={sourceCopywriter || ''}
                    placeholder="Source Copywriter"
                    fullWidth
                    disabled={!source || source === ''}
                  >
                    {sourceCopywriters?.map((value, i) => (
                      <MenuItem
                        key={`${value.username}-${i}`}
                        value={value.username}
                        onClick={() => {
                          setSourceCopywriter(value.username);
                        }}
                      >
                        {value?.username}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          {/* Row 2 */}
          <Grid container>
            {setup.map((val, idx) => {
              return (
                <Fragment key={idx}>
                  {idx >= 1 && (
                    <Box
                      sx={{ borderTop: '1px solid white', width: '100%' }}
                    ></Box>
                  )}

                  {/* Row 1 */}
                  <Grid item xs={5} md={4}>
                    <Box
                      width={desktop ? 150 : 100}
                      mt={1}
                      sx={{ marginTop: idx >= 1 ? '2em' : '0' }}
                    >
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Target</InputLabel>
                        <Select
                          value={setup[idx][0]}
                          placeholder="Target"
                          fullWidth
                          onChange={(e) => {
                            setTarget(e.target.value as string, idx);
                          }}
                        >
                          {targetLangs.map((lang) => (
                            <MenuItem
                              key={lang.lang_name}
                              value={lang.lang_name}
                            >
                              {lang.lang_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={7} md={8}>
                    <Box
                      width={desktop ? 180 : 160}
                      mt={1}
                      sx={{ marginTop: idx >= 1 ? '2em' : '0' }}
                    >
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>
                          {desktop ? 'Target Copywriter' : 'Target Copywr...'}
                        </InputLabel>
                        <Select
                          placeholder="Target Copywriter"
                          fullWidth
                          disabled={!val[0] || val[0] === ''}
                        >
                          {targetCopywriters?.[idx]?.map((value, i) => (
                            <MenuItem
                              key={`${value.username}-${i}`}
                              value={value.username}
                              onClick={() => {
                                setTargetCopywriter(value.username, idx);
                              }}
                            >
                              {value?.username}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* Row 2 */}
                  <Grid item xs={12}>
                    <Box width={desktop ? 150 : 100} mt={1}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>Voice</InputLabel>
                        <Select
                          value={setup[idx][1]}
                          placeholder="Voice"
                          fullWidth
                          onChange={(e) => {
                            setVoice(e.target.value as string, idx);
                          }}
                        >
                          {targetLangs
                            .find((i) => i.lang_name === val[0])
                            ?.voices.map((item) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* CHECKBOXES */}
                  <Grid item>
                    <Box mt={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={JSON.parse(
                              setup[idx][3].split(':')[1].toLowerCase(),
                            )}
                            onChange={() =>
                              setLipSync(
                                !JSON.parse(
                                  setup[idx][3].split(':')[1].toLowerCase(),
                                ),
                                idx,
                              )
                            }
                          />
                        }
                        label="Lip Sync"
                      />
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box mt={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={JSON.parse(
                              setup[idx][4].split(':')[1].toLowerCase(),
                            )}
                            onChange={() =>
                              setBas(
                                !JSON.parse(
                                  setup[idx][4].split(':')[1].toLowerCase(),
                                ),
                                idx,
                              )
                            }
                          />
                        }
                        label="BAS"
                      />
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box mt={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={JSON.parse(
                              setup[idx][2].split(':')[1].toLowerCase(),
                            )}
                            onChange={() =>
                              setSubtitle(
                                !JSON.parse(
                                  setup[idx][2].split(':')[1].toLowerCase(),
                                ),
                                idx,
                              )
                            }
                          />
                        }
                        label="Subtitles"
                      />
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box mt={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={JSON.parse(
                              setup[idx][5].split(':')[1].toLowerCase(),
                            )}
                            onChange={() =>
                              setMultispeaker(
                                !JSON.parse(
                                  setup[idx][5].split(':')[1].toLowerCase(),
                                ),
                                idx,
                              )
                            }
                          />
                        }
                        label="Multispeaker"
                      />
                    </Box>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>

          <Box textAlign="center">
            <IconButton color="primary" onClick={onAddSetup}>
              <AddCircle fontSize="large" />
            </IconButton>
          </Box>
        </Box>

        <Box>
          <Uploader settings={uploadSettings} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => {
          addSetup(defaultSetup)
          onClose()
        }} variant="outlined" color="secondary">
          Cancel
        </Button>

        <Button
          disabled={!uploadId || !title || isLoading}
          onClick={createProject}
          variant="contained"
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProjectDialog;
