/* eslint no-shadow: ["error", { "allow": ["projects"] }] */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Languages from '../../../../context/Languages';
import { request } from '../../../../shared/utils/api';
import { BACKEND_URL } from '../../../../shared/utils/config';
import { Project } from '../../../Home/Home.interface';
import { Order } from '../../../Order/Order.interface';

interface Props {
  open: boolean;
  selectedIds: any;
  currentProjects?: Project[];
  onClose: () => void;
  onSubmit: (e?: any) => void;
}

const RemoveProjectDialog: FC<Props> = ({
  open,
  onClose,
  onSubmit,
  selectedIds,
  currentProjects,
}) => {
  const removeProject = () => {
    request(`api/delete_project/`, {
      method: 'POST',
      body: JSON.stringify({
        delete_project: selectedIds,
      }),
    }).then((r) => {
      onClose();
      onSubmit();
    });
  };

  const currentProjectNames = currentProjects?.map((currentProject, index) => (
    <Typography key={`${currentProject.name}-${index}`}>
      {currentProject.name}
    </Typography>
  ));

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>Close project</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography variant="body1">
            Are you sure you want to close{' '}
            {selectedIds?.length > 1 ? 'these' : ''} project
            {selectedIds?.length > 1 ? 's' : ''}?
          </Typography>
        </Box>

        {selectedIds?.length > 1 && (
          <Box sx={{ flexDirection: 'column' }} width={'100%'}>
            {currentProjectNames}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button onClick={removeProject} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveProjectDialog;
