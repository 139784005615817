import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { useHistory, useLocation } from 'react-router-dom';
import { MovieFilter, Subtitles } from '@material-ui/icons';
import { Project } from '../../AdminDashboard.interface';
import { request } from '../../../../shared/utils/api';

interface Props {
  data: Project[];
  totalProjectCount: number;
  loading: boolean;
  selectedIds: any;
  setSelectedIds: any;
  showAll: boolean;
  setShowAll: Function;
  refreshDataGrid: boolean;
}

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Project name', minWidth: 150, flex: 1 },
  {
    field: 'from_lang',
    headerName: 'From lang',
    align: 'center',
    headerAlign: 'center',
    description: 'Source language',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'to_lang',
    headerName: 'To lang',
    align: 'center',
    headerAlign: 'center',
    description: 'Target language',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'duration',
    headerName: 'Duration(min)',
    align: 'center',
    headerAlign: 'center',
    description: 'Video duration',
    minWidth: 100,
    flex: 1,
    valueFormatter: (params) => {
      let value;
      if (params.value) {
        value = Math.ceil(Number(params.value) / 60);
      } else {
        value = null;
      }
      return value;
    },
  },
  {
    field: 'source_copywriter',
    headerName: 'Source Copywriter',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'target_copywriter',
    headerName: 'Target Copywriter',
    minWidth: 150,
    flex: 1,
  },
  // {
  //   field: 'admin',
  //   headerName: 'Admin',
  //   editable: true,
  //   width: 150,
  //   renderEditCell: (params) => {
  //     return (
  //       <Select
  //         value={params.value as string}
  //         placeholder="Admin"
  //         fullWidth
  //         onChange={(e) => {
  //           params.api.setEditCellValue({
  //             id: params.id,
  //             field: params.field,
  //             value: e.target.value as string,
  //           });
  //           params.api.commitCellChange({ id: params.id, field: params.field });
  //           params.api.setCellMode(params.id, params.field, 'view');
  //           setAdmin(
  //             e.target.value as string,
  //             params.row.project_uuid,
  //             params.row.to_lang,
  //           );
  //         }}
  //       >
  //         {admins.map((admin) => (
  //           <MenuItem key={admin as string} value={admin as string}>
  //             {admin}
  //           </MenuItem>
  //         ))}
  //       </Select>
  //     );
  //   },
  // },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
    sortComparator: (v1, v2) => {
      const sortingOrder = {
        0: 'Re-opened',
        1: 'Not started',
        2: 'Generating',
        3: 'Generated',
        4: 'Closed',
      };

      let currentOrderKeyA = 0;
      let currentOrderKeyB = 0;

      for (let a in Object.keys(sortingOrder)) {
        if (v1 === sortingOrder[a]) {
          currentOrderKeyA = Number(a);
        }

        if (v2 === sortingOrder[a]) {
          currentOrderKeyB = Number(a);
        }
      }

      if (currentOrderKeyA < currentOrderKeyB) {
        return -1;
      }
      return 1;
    },
  },
  {
    field: 'result_link',
    headerName: 'Results',
    minWidth: 120,
    flex: 1,
    renderCell: (params) => {
      if (params.value) {
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => {
                request('api/get_download_link/', {
                  method: 'POST',
                  body: JSON.stringify({
                    project_uuid: params.row.project_uuid,
                    to_lang: params.row.to_lang,
                  }),
                }).then((r) => {
                  const win = window.open(r.result_link, 'Download');
                  if (win != null) {
                    win.focus();
                  }
                });
              }}
            >
              <MovieFilter />
            </IconButton>
            {params.row.subs_link && (
              <IconButton
                color="primary"
                onClick={() => {
                  request('api/get_download_link/', {
                    method: 'POST',
                    body: JSON.stringify({
                      project_uuid: params.row.project_uuid,
                      to_lang: params.row.to_lang,
                    }),
                  })
                  .then((r) => {
                    const fileURL = r.subs_link;
                    // Загрузка файла
                    fetch(fileURL, {
                      method: 'GET',
                    })
                    .then((response) => response.blob())
                    .then((blob) => {
                      // Создаем ссылку для скачивания
                      const url = window.URL.createObjectURL(new Blob([blob]));
                      const link = document.createElement('a');
                      link.href = url;
                      const fileName = fileURL.split('/').pop();
                      link.setAttribute('download', fileName);
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    });
                  });
                }}
              >
                <Subtitles />
              </IconButton>
            )}
          </>
        );
      }
      return <></>;
    },
  },
];

const sortModel = [
  {
    field: 'status',
    sort: 'asc' as GridSortDirection,
  },
];

const ProjectsTable: FC<Props> = ({
  data = [],
  totalProjectCount,
  loading,
  selectedIds,
  setSelectedIds,
  refreshDataGrid,
  showAll,
  setShowAll,
}) => {
  const history = useHistory();
  const [admins, setAdmins] = useState<any[]>([]);

  useEffect(() => {
    request(`api/get_admins/`).then((r) => {
      let admins = r?.admins_list;
      setAdmins(admins);
    });
  }, []);

  function setAdmin(admin, id, toLang) {
    request(`api/edit_project_setup/`, {
      method: 'POST',
      body: JSON.stringify({
        project_uuid: id,
        to_lang: toLang,
        params_to_edit: [['admin', admin]],
      }),
    });
  }

  return (
    <>
      <Box
        mb={3}
        sx={{
          display: 'inline-flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box>
          <Typography variant="h6">
            Total projects: <b>{totalProjectCount}</b>
          </Typography>
        </Box>

        <ButtonGroup variant="outlined" size="small">
          <Button
            color="default"
            size="small"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'Hide Closed' : 'Show All'}
          </Button>
        </ButtonGroup>
      </Box>

      <div style={{ width: '100%' }}>
        <DataGrid
          key={refreshDataGrid ? 'refresh-data-grid-key' : 'data-grid-key'}
          loading={loading}
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => {
            setSelectedIds(ids);
          }}
          rows={data}
          columns={columns}
          onCellClick={(e) => {
            if (
              e.colDef.field !== 'admin' &&
              e.colDef.field !== 'result_link'
            ) {
              return history.push(
                `/project/${e.row.project_uuid}/${e.row.to_lang}`,
              );
            }
          }}
          rowHeight={35}
          rowsPerPageOptions={[50]}
          autoHeight
          pageSize={50}
          pagination
          getRowId={(row) => `${row.project_uuid}|${row.to_lang}`}
        />
      </div>
    </>
  );
};

export default ProjectsTable;
