import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Languages from '../../../../context/Languages';
import { request } from '../../../../shared/utils/api';
import { BACKEND_URL } from '../../../../shared/utils/config';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (e?: any) => void;
}

const CreateOrderDialog: FC<Props> = ({ open, onClose, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [dueDate, setDueDate] = useState('');
  const h = useHistory();

  const createOrder = () => {
    request(`api/create_new_order/`, {
      method: 'POST',
      body: JSON.stringify({
        client_email: email,
        due_date_utc: dueDate,

      }),
    }).then((r) => {
      onClose();
      onSubmit();
      h.push(`/orders/${r.order_id}`);
    });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>Create new order</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
          margin="dense"
          label="Client’s email:"
          type="email"
          fullWidth
        />
        <TextField
            onChange={(e) => setDueDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
            label="Due date:"
            type="date"
            fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button onClick={createOrder} variant="contained" color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrderDialog;
