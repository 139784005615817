import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
  ButtonGroup,
} from '@material-ui/core';
import {
  CreateOutlined,
  ArrowBack,
  DeleteOutlined,
  DoneOutlined,
} from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import useSWR from 'swr';
import { Link, useParams, useLocation } from 'react-router-dom';
import ProjectsTable from './components/ProjectsTable';
import { request } from '../../shared/utils/api';
import { Project, ProjectsDTO } from './AdminDashboard.interface';
import CreateProjectDialog from './components/CreateProjectDialog';
import RemoveProjectDialog from './components/RemoveProjectDialog';
import ReopenProjectDialog from './components/ReopenProjectDialog';
import Auth from '../../context/Auth';
import { styled } from '@material-ui/styles';
import { IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const TopSection = styled('div')(({ theme }: { theme: any }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    marginTop: '2em',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '2em',
  },
}));

const HeadingContainer = styled('div')(({ theme }: { theme: any }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    fontSize: '0.4em',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: '2em',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Heading = styled('div')(({ theme }: { theme: any }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    fontSize: '0.4em',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginLeft: '-20em',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    padding: '0',
  },
}));

const ButtonContainer = styled('div')(({ theme }: { theme: any }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const AdminDashboard = () => {
  const auth = Auth.useContainer();
  const location: {
    state: { previousPage: number; previousShowAll: boolean };
  } = useLocation();
  const classes = useStyles();
  const desktop = useMediaQuery('(min-width:600px)');
  const [openCreate, setOpenCreate] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [reopenProject, setReopenProject] = useState(false);
  const [refreshDataGrid, setRefreshDataGrid] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]) as any;
  const [filteredData, setFilteredData] = useState<Project[] | undefined>();
  const [currentProjects, setCurrentProjects] = useState<
    Project[] | undefined
  >();

  const { id } = useParams<{ id: string }>();

  const { data, revalidate } = useSWR<ProjectsDTO>(
    ['api/get_projects_by_orderid/', id],
    (url, orderId) =>
      request(url, {
        method: 'POST',
        body: JSON.stringify({ order_id: orderId }),
      }),
  );

  useEffect(() => {
    if (selectedIds) {
      setCurrentProjects(
        data?.projects?.filter(({ project_uuid, to_lang }) =>
          selectedIds.includes(`${project_uuid}|${to_lang}`),
        ),
      );
    }
  }, [selectedIds]);

  const disableOpenButton = () => {
    const closedProjects =
      currentProjects?.filter((project) => project.status === 'Closed') || [];

    if (closedProjects?.length > 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (showAll && data?.projects) {
      setFilteredData(data?.projects);
    } else if (!showAll && data?.projects) {
      const openProjects = data?.projects?.filter(
        (project) => project.status !== 'Closed',
      );
      setFilteredData(openProjects);
    }
  }, [data, showAll]);

  return (
    <Box>
      <TopSection>
        <HeadingContainer>
          <Tooltip title="Get back to Orders">
            <Link
              to={{
                pathname: '/orders',
                state: { previousPage: location?.state?.previousPage || 0, previousShowAll: location?.state?.previousShowAll || false },
              }}
            >
              <IconButton sx={{ padding: '0 initial', color: '#fff' }}>
                <ArrowBack />
              </IconButton>
            </Link>
          </Tooltip>

          <Heading>
            <Typography variant="h6" component="h1">
              Order #{id}
            </Typography>
          </Heading>
        </HeadingContainer>

        <ButtonContainer>
          <ButtonGroup variant="outlined" size="small">
            <Button
              startIcon={<CreateOutlined />}
              color="primary"
              onClick={() => setOpenCreate(true)}
            >
              {desktop ? 'New Project' : 'New'}
            </Button>

            {showAll && auth.staff && (
              <Button
                startIcon={<DoneOutlined />}
                color="default"
                size="small"
                disabled={!selectedIds.length || disableOpenButton()}
                onClick={() => setReopenProject(true)}
              >
                {desktop ? 'Re-Open Project' : 'Open'}
              </Button>
            )}

            <Button
              startIcon={<DeleteOutlined />}
              color="secondary"
              disabled={
                !selectedIds.length ||
                !currentProjects?.some((project) => project.status !== 'Closed')
              }
              onClick={() => setOpenRemove(true)}
            >
              {desktop
                ? `Close Project${selectedIds?.length > 1 ? 's' : ''}`
                : 'Close'}
            </Button>
          </ButtonGroup>
        </ButtonContainer>
      </TopSection>

      <Box mt={3}>
        <Paper elevation={5}>
          <Box p={3}>
            <ProjectsTable
              loading={!filteredData}
              data={filteredData || []}
              totalProjectCount={data?.projects.length || 0}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              refreshDataGrid={refreshDataGrid}
              showAll={showAll}
              setShowAll={setShowAll}
            />
          </Box>
        </Paper>
      </Box>

      <CreateProjectDialog
        open={openCreate}
        orderId={id}
        onClose={() => setOpenCreate(false)}
        onSubmit={() => {
          revalidate();
        }}
      />

      <ReopenProjectDialog
        open={reopenProject}
        onClose={() => setReopenProject(false)}
        onSubmit={() => {
          setSelectedIds([]);
          revalidate();
          setRefreshDataGrid(!refreshDataGrid);
        }}
        selectedIds={selectedIds}
      />

      <RemoveProjectDialog
        open={openRemove}
        onClose={() => setOpenRemove(false)}
        onSubmit={() => {
          setSelectedIds([]);
          revalidate();
          setRefreshDataGrid(!refreshDataGrid);
        }}
        selectedIds={selectedIds}
        currentProjects={currentProjects}
      />
    </Box>
  );
};

export default AdminDashboard;
