import {
  AppBar,
  Box,
  Button,
  createStyles,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ExitToAppOutlined, BugReportOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Auth from '../../../context/Auth';
import { request } from '../../utils/api';
import BugReportModal from '../BugReportModal/BugReportModal';
import { IBugReport } from '../../interfaces/bugReport';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      flexGrow: 1,
    },
  }),
);

const Header = () => {
  const [showBugReportModal, setShowBugReportModal] = useState(false);
  const [errors, setErrors] = useState<string|undefined>()

  const classes = useStyles();

  const h = useHistory();
  const auth = Auth.useContainer();

  const logout = () => {
    auth.logout();
    setTimeout(() => {
      h.push('/sign-in');
    }, 200);
  };

  const submitBugReport = async (values: IBugReport) => {
    try {
      const response = await request('api/send_bug_report/', {
        method: 'POST',
        body: JSON.stringify(values)
      })

      if (response.status === 'Success') {
        setShowBugReportModal(false)
      }
    } catch (err) {
      setErrors('Failed to send bug report, please try again later or contact an admin.')
    }
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Blanc
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {auth.staff && (
              <Button
                startIcon={<BugReportOutlined />}
                color="inherit"
                variant="text"
                onClick={() => setShowBugReportModal(true)}
              >
                Submit a bug
              </Button>
            )}

            <Button
              startIcon={<ExitToAppOutlined />}
              color="secondary"
              variant="text"
              onClick={logout}
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <BugReportModal
        open={showBugReportModal}
        onClose={() => setShowBugReportModal(false)}
        onSubmit={submitBugReport}
        errors={errors}
      />
    </>
  );
};

export default Header;
